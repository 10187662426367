import React, { useState } from "react";
import * as API from "../api/API";
import { ADD_USER_DETAILS } from "../redux/constants/ActionTypes";
import store from "../redux/reducers";
import { AddToIPFS } from "../services/IpfsService";
import { createCVObject, oldCreateCVObject } from "./cvObject";
import { jwtDecode } from "./jwt";
import { symmetricDecryption, symmetricEncryption } from "./encryption";
import { urlToHash } from "./ipfs";
import Web3 from "web3";

export const getArrayData = (array, type) => {
  let newArr = [];

  if (type === "techStack") {
    if (array?.length > 0)
      array?.map((data) => {
        newArr?.push({
          value: data?.data?.data,
          label:
            data?.data?.data?.charAt(0)?.toUpperCase() +
            data?.data?.data?.slice(1),
        });
      });

    return newArr;
  } else if (type === "stageData") {
    return;
  } else {
    if (array?.length > 0)
      array?.map((data) => {
        let newObject = {};
        if (data?.data) {
          var keys = Object.keys(data?.data);
          keys.map((fieldKey) => {
            if (fieldKey === "isPublic")
              newObject[fieldKey] =
                data?.data[fieldKey]?.data === "true" ? true : false;
            else newObject[fieldKey] = data?.data[fieldKey]?.data;
          });
          newArr?.push(newObject);
        }
      });

    return newArr;
  }
};

export const setEmptyEditedByArray = async (latestIpfsData) => {
  // Set editedBy array empty
  Object.keys(latestIpfsData).forEach((key) => {
    if (key !== "previousIpfsHash") {
      if (
        key === "customData" ||
        key === "achievements" ||
        key === "educationData" ||
        key === "workData" ||
        key === "techStack" ||
        key === "stageData"
      ) {
        latestIpfsData[key].forEach((item) => {
          item.editedBy = [];
        });
      } else {
        latestIpfsData[key].editedBy = [];
      }
    }
  });
};

export const dispatchEditObject = async (
  secretKey,
  setLoading,
  productData,
  privacyData,
  currentUser,
  enqueueSnackbar,
  type,
  latestIpfsData,
  setOverLay,
  history,
  authType,
  authUser,
  modifyUser
) => {
  setLoading(true);
  try {
    // Get logged in user
    let getLatestIpfsData = latestIpfsData;
    const user = jwtDecode();

    const editedByData = [
      {
        editorPublicKey: modifyUser.publicKey,
        editorAlias: modifyUser.alias,
        ipfsHash: "",
        createdAt: Date.now(),
      },
    ];

    const ipfsHash = urlToHash(currentUser.latestIpfsUrl.ipfsUrl);

    //workData-add and workData-edit
    if (type === "workData-add") {
      // Format into CV Object
      const formattedObject = createCVObject(
        productData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      // Set editedBy array empty
      await setEmptyEditedByArray(getLatestIpfsData);

      if (formattedObject.workData.length > 0) {
        formattedObject.workData.forEach((i) => {
          i.editedBy = editedByData;
        });
      }

      if (getLatestIpfsData?.workData) {
        let newWorkData = [
          ...getLatestIpfsData.workData,
          ...formattedObject.workData,
        ];
        delete getLatestIpfsData.workData;
        getLatestIpfsData.workData = newWorkData;
      } else {
        getLatestIpfsData.workData = formattedObject.workData;
      }
    } else if (type === "workData-edit") {
      // Set editedBy array empty
      await setEmptyEditedByArray(getLatestIpfsData);

      productData.workData?.map((item) => {
        if (item?.data?.isPublic?.data === "false") {
          Object.entries(item.data).forEach((e) => {
            const [key, value] = e;
            value.data = symmetricEncryption(value.data, secretKey);
            value.encryptionType = "SYMMETRIC";
          });
          item.isPublic = false;
        }
      });
      getLatestIpfsData.workData = productData.workData;
    }

    //eduData-add and eduData-edit
    else if (type === "eduData-add") {
      // Format into CV Object
      const formattedObject = createCVObject(
        productData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      // Set editedBy array empty
      await setEmptyEditedByArray(getLatestIpfsData);

      if (formattedObject.educationData.length > 0) {
        formattedObject.educationData.forEach((i) => {
          i.editedBy = editedByData;
        });
      }

      if (getLatestIpfsData?.educationData) {
        let newEduData = [
          ...getLatestIpfsData.educationData,
          ...formattedObject.educationData,
        ];
        delete getLatestIpfsData.educationData;
        getLatestIpfsData.educationData = newEduData;
      } else {
        getLatestIpfsData.educationData = formattedObject.educationData;
      }
    } else if (type === "eduData-edit") {
      // Set editedBy array empty
      await setEmptyEditedByArray(getLatestIpfsData);

      productData.educationData?.map((item) => {
        if (item?.data?.isPublic?.data === "false") {
          Object.entries(item.data).forEach((e) => {
            const [key, value] = e;
            value.data = symmetricEncryption(value.data, secretKey);
            value.encryptionType = "SYMMETRIC";
          });
          item.isPublic = false;
        }
      });
      getLatestIpfsData.educationData = productData.educationData;
    }

    //achievementsData-add and edit
    else if (type === "achievementsData-add") {
      // Format into CV Object
      const formattedObject = createCVObject(
        productData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      // Set editedBy array empty
      await setEmptyEditedByArray(getLatestIpfsData);

      if (formattedObject.achievements.length > 0) {
        formattedObject.achievements.forEach((i) => {
          i.editedBy = editedByData;
        });
      }

      if (getLatestIpfsData?.achievements) {
        let newAchieveData = [
          ...getLatestIpfsData.achievements,
          ...formattedObject.achievements,
        ];
        delete getLatestIpfsData.achievements;
        getLatestIpfsData.achievements = newAchieveData;
      } else {
        getLatestIpfsData.achievements = formattedObject.achievements;
      }
    } else if (type === "achievementsData-edit") {
      // Set editedBy array empty
      await setEmptyEditedByArray(getLatestIpfsData);

      productData.achievements?.map((item) => {
        if (item?.data?.isPublic?.data === "false") {
          Object.entries(item.data).forEach((e) => {
            const [key, value] = e;
            value.data = symmetricEncryption(value.data, secretKey);
            value.encryptionType = "SYMMETRIC";
          });
          item.isPublic = false;
        }
      });
      getLatestIpfsData.achievements = productData.achievements;
    }

    //customData-add and edit
    else if (type === "customData-add") {
      // Format into CV Object
      const formattedObject = createCVObject(
        productData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      // Set editedBy array empty
      await setEmptyEditedByArray(getLatestIpfsData);

      if (formattedObject.customData.length > 0) {
        formattedObject.customData.forEach((i) => {
          i.editedBy = editedByData;
        });
      }

      if (getLatestIpfsData?.customData) {
        let newAchieveData = [
          ...getLatestIpfsData.customData,
          ...formattedObject.customData,
        ];
        delete getLatestIpfsData.customData;
        getLatestIpfsData.customData = newAchieveData;
      } else {
        getLatestIpfsData.customData = formattedObject.customData;
      }
    } else if (type === "customData-edit") {
      // Set editedBy array empty
      await setEmptyEditedByArray(getLatestIpfsData);

      productData.customData?.map((item) => {
        if (item.isPublic === false || item?.data?.isPublic?.data === "false") {
          item?.data &&
            Object.entries(item.data).forEach((e) => {
              const [key, value] = e;
              value.data = symmetricEncryption(value.data, secretKey);
              value.encryptionType = "SYMMETRIC";
            });
          item.isPublic = false;
        }
      });

      getLatestIpfsData.customData = productData.customData;
    }

    //stageData-add and edit
    else if (type === "stageData-add") {
      // Format into CV Object
      const formattedObject = createCVObject(
        productData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      // Set editedBy array empty
      await setEmptyEditedByArray(getLatestIpfsData);

      if (formattedObject.stageData.length > 0) {
        formattedObject.stageData.forEach((i) => {
          i.editedBy = editedByData;
        });
      }

      if (getLatestIpfsData?.stageData) {
        let newAchieveData = [
          ...getLatestIpfsData.stageData,
          ...formattedObject.stageData,
        ];
        delete getLatestIpfsData.stageData;
        getLatestIpfsData.stageData = newAchieveData;
      } else {
        getLatestIpfsData.stageData = formattedObject.stageData;
      }
    } else if (type === "stageData-edit") {
      // Set editedBy array empty
      await setEmptyEditedByArray(getLatestIpfsData);

      productData.stageData?.map((item) => {
        if (item?.data?.isPublic?.data === "false") {
          Object.entries(item.data).forEach((e) => {
            const [key, value] = e;
            if (key !== "customStageData") {
              value.data = symmetricEncryption(value.data, secretKey);
              value.encryptionType = "SYMMETRIC";
            }
          });
          item.isPublic = false;
        }
      });
      getLatestIpfsData.stageData = productData.stageData;
    }

    getLatestIpfsData.previousIpfsHash = ipfsHash;

    // get signature
    const evmPrivateKey = authUser.accounts[2].encryptedSecret;
    const privateKey = symmetricDecryption(evmPrivateKey, secretKey);
    let provider = new Web3.providers.HttpProvider(
      "https://polygon-rpc.com/"
    );
    const blockchain = "MATIC";

    const web3 = new Web3(provider);

    let signerAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
    let contractAddress = "0x7bbb46a6Df9144272cEc505b05BCD82Fefe123B0";

    let DeproTraceABI = require("./abi/DeproTrace.json");
    const chainId = await web3.eth.getChainId();

    let domainData = {
      name: "MetaTransaction",
      version: "1",
      chainId: chainId, // Matic Testnet
      verifyingContract: contractAddress,
    };

    const domainType = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ];

    const metaTransactionType = [
      { name: "nonce", type: "uint256" },
      { name: "from", type: "address" },
    ];

    let DeproTrace = new web3.eth.Contract(DeproTraceABI, contractAddress);
    let nonce = await DeproTrace.methods.nonces(signerAccount.address).call();

    let message = {};

    message.nonce = parseInt(nonce);
    message.from = signerAccount.address;

    const dataToSign = JSON.stringify({
      types: {
        EIP712Domain: domainType,
        MetaTransaction: metaTransactionType,
      },
      domain: domainData,
      primaryType: "MetaTransaction",
      message: message,
    });

    const hashedData = web3.utils.sha3(dataToSign);
    const signature = web3.eth.accounts.sign(
      hashedData,
      signerAccount.privateKey
    );

    let signatureObj = {
      signR: signature.r,
      signS: signature.s,
      signV: signature.v,
      messageHash: signature.message,
      profileAddress: signerAccount.address,
    };

    // add product to ipfs
    const productIpfsHash = await AddToIPFS(JSON.stringify(getLatestIpfsData));

    const data = {
      path: { id: user.publicKey },
      token: localStorage.niftoken,
      body: {
        latestIpfsUrl: {
          ipfsUrl: `https://niftron.infura-ipfs.io/ipfs/${productIpfsHash.ipfsHash}`,
          timestamp: Date.now(),
        },
        ipfsHash: productIpfsHash.ipfsHash,
        signature: signatureObj,
        network: blockchain,
      },
    };
    const response =
      authType === "1"
        ? await API.updateCredentialProvider(data)
        : await API.addCv(data);

    if (response?.data?.code === 201) {
      const userData = await API.me({
        token: localStorage.niftoken,
      });
      if (userData?.data?.code === 200) {
        store.dispatch({
          type: ADD_USER_DETAILS,
          payload: userData?.data?.data[0],
        });
        enqueueSnackbar("Successfully made changes", {
          variant: "success",
        });
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
    setOverLay(false);
    authType === "1"
      ? history.push("/dashboard/profile")
      : history.push("/dashboard/my-profile");
  }
};
