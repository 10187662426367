import React, { useState } from "react";
import styled from "styled-components";
import { IconButton, useMediaQuery } from "@mui/material";
import { getUserSession, issueItem } from "../../api/API";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { SubHeading } from "../../components/shared/Typography/Typo";
import CSVReader from "./CSVUploader";
import ActionConfirm from "../../components/shared/Form/FormComponents/ActionConfirm";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import TokenCredentialsTableView from "../../pages/CertificatesView/TokenCredentialsTableView";
import { symmetricDecryption } from "../../services/encryptionService";
import Web3 from "web3";
import { FlexSpaceBetweenContainer } from "../../components/shared/ProductProfile/ProductProfile";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconWrapper } from "./IssueCertificateForm";

export const IssueCertificateFormBulk = ({ setMainView }) => {
  //eslint-disable-next-line
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const currentUser = useSelector((state) => state.currentUser);
  const [status, setStatus] = useState(0);
  const [view, setView] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const matches = useMediaQuery("(max-width:1368px)");
  const [certificateData, setCertificateData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  let slicedCertificateData = certificateData?.data?.slice(1);
  const latestIpfsData = useSelector((state) => state.latestIpfsData);

  // Issuing multiple certificates
  const issueCertificateInBulk = async (secretKey) => {
    if (currentUser?.latestIpfsUrl) {
      setLoading(true);
      let certificateObject = {};
      let body = {
        alias: latestIpfsData?.name?.data?.data,
        senderPublicKey: currentUser.publicKey,
        type: "education",
      };

      const evmPrivateKey = authUser.accounts[2].encryptedSecret;
      const privateKey = symmetricDecryption(evmPrivateKey, secretKey);
      const blockchain = slicedCertificateData[0][2];
      let provider = "";
      if (blockchain === "MATIC") {
        provider = new Web3.providers.HttpProvider("https://polygon-rpc.com/");
      } else if (blockchain === "MUMBAI") {
        provider = new Web3.providers.HttpProvider(
          "https://rpc-mumbai.maticvigil.com/"
        );
      }
      const web3 = new Web3(provider);

      const signerAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      let contractAddress = "";
      if (blockchain === "MATIC") {
        contractAddress = "0x2173ad61D4eC8a4b1F288B0631b7b29972D23B0C"; //"0x44de733c9d0c3517906c9E8520DC6E0c78088cf9";
      } else {
        contractAddress = "0x1322D0894dF4355193625F7cdeFCC957BDD65ade";
      }

      let DeproABI = require("../../utils/abi/DeproNFT.json");
      let DeproNFT = new web3.eth.Contract(DeproABI, contractAddress);
      let nonces = await DeproNFT.methods.nonces(signerAccount.address).call();

      const chainId = await web3.eth.getChainId();
      let domainData = {
        name: "MetaTransaction",
        version: "1",
        chainId: chainId, // Matic Testnet
        verifyingContract: contractAddress,
      };

      const domainType = [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ];

      const metaTransactionType = [
        { name: "nonce", type: "uint256" },
        { name: "from", type: "address" },
      ];
      //console.log("Sliced Token: ", slicedCertificateData[0][2]);

      try {
        for (var i = 0; i < slicedCertificateData.length; i++) {
          for (var x = 0; x < slicedCertificateData[0].length; x++) {
            certificateObject[certificateData.data[0][x]] =
              slicedCertificateData[i][x];
          }

          let message = {};
          message.nonce = nonces.toString();
          message.from = signerAccount.address;

          const dataToSign = JSON.stringify({
            types: {
              EIP712Domain: domainType,
              MetaTransaction: metaTransactionType,
            },
            domain: domainData,
            primaryType: "MetaTransaction",
            message: message,
          });

          const hashedData = web3.utils.sha3(dataToSign);
          const signature = web3.eth.accounts.sign(
            hashedData,
            signerAccount.privateKey
          );

          nonces++;

          let creatorSignature = {
            signR: signature.r,
            signS: signature.s,
            signV: signature.v,
            messageHash: signature.message,
            evmAddress: signerAccount.address,
          };

          body.creatorSignature = creatorSignature;
          const certificateBody = Object.assign(body, certificateObject);

          console.log("Token BODY: ", certificateBody);

          const response = await issueItem({
            body: certificateBody,
            token: localStorage.niftoken,
          });
          if (response.status === 200) {
            throw new Error("Token Quota Exceeded!");
          }
          // if (response.status === 200) {
          //   enqueueSnackbar("Token Quota Exceeded!", {
          //     variant: "warning",
          //   });
          // }
          if (response.data.code === 201) {
            setStatus(i);
          }
        }
        if (slicedCertificateData.length === i) {
          enqueueSnackbar("Token Issue Success!", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(`Token Issue Failed at Row ${i}!`, {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
        setStatus(0);
        setShowAction(false);
        setView(0);
      }
    } else {
      enqueueSnackbar(`Please fill Creator data to proceed`, {
        variant: "error",
      });
      setShowAction(false);
    }
  };

  if (view === 1) {
    return (
      <>
        {showAction && (
          <ActionConfirm
            status={`${status}/${slicedCertificateData.length}`}
            successMessage={{
              message: "Requesting relayer to mint certificates",
              width: "17rem",
            }}
            warningMessage={"Are you sure you want to submit?"}
            loading={loading}
            setShowAction={setShowAction}
            authTypeInput={authUser.authType}
            publicKey={authUser.publicKey}
            encryptedSecret={authUser.encryptedSecret}
            onSecretKey={issueCertificateInBulk}
          />
        )}
        <TokenCredentialsTableView
          data={certificateData.data}
          setShowAction={setShowAction}
          setView={setView}
        />
      </>
    );
  } else {
    return (
      <Container>
        <FlexContainerDiv id="tokenBulk-form" name="tokenBulk-form">
          <FlexSpaceBetweenContainer>
            <SubHeading
              primary
              style={{
                gridColumn: `${!matches ? "1/3" : "unset"}`,
                margin: "1rem 0rem",
              }}
            >
              Upload CSV to generate token details
            </SubHeading>{" "}
            <IconWrapper>
              <IconButton
                onClick={() => {
                  setMainView(0);
                }}
              >
                <ArrowBackIosNewIcon
                  style={{ color: "#1e1b3b" }}
                  sx={{ fontSize: 20 }}
                />
              </IconButton>
            </IconWrapper>
          </FlexSpaceBetweenContainer>

          <CSVReader
            setCertificateData={setCertificateData}
            style={{ gridRow: "span 2" }}
            setPreview={setView}
          />
        </FlexContainerDiv>
        <ColumnContainer>
          <ButtonWrapper>
            <CustomButton
              login
              text="Preview"
              disabled={certificateData.length === 0}
              onclick={() => setView(1)}
            />
          </ButtonWrapper>
        </ColumnContainer>
      </Container>
    );
  }
};

export default IssueCertificateFormBulk;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
`;

export const FlexContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  padding-top: 1rem;
  gap: 2rem;
  display: flex;
  justify-content: flex-end;
`;

export const DroppableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
`;
export const InnerContainer = styled.div`
  height: 8rem;
  text-align: center;
  word-wrap: break-word;
  border: dashed 0.75px #ae9bf0a3;
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #c3d0e147;
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 1rem;
  justify-content: center;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
export const DisabledView = styled.div`
  display: flex;
  color: #2c2858;
  padding: 0.5rem;
  background-color: #eff2f5;
  border-radius: 5px;
  align-items: center;
  word-break: break-all;
`;
