import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";

import DocumentContainer from "../../components/shared/Form/StageForm/DocumentContainer";
import VerifiedStatusContainer from "../../components/shared/Profile/VerifiedStatusContainer";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import ProductProfileHeader, {
  SectionContainer,
} from "../../components/shared/ProductProfile/ProductProfileHeader";
import { RequestVerification } from "./../../components/shared/Profile/RequestVerification";
import { ViewVerifiedDetails } from "./../../components/shared/ProductProfile/ViewVerifiedDetails";
import AdditionalDetails from "../../components/shared/Profile/AdditionalDetails";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import EncryptedData from "../../components/shared/Profile/EncryptedData";
import DocumentProfileHeader from "./../../components/shared/DocumentProfile/DocumentProfileHeader";

const ProviderViewDocument = ({ latestIpfsData, ownerShipDetails }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [isPublic, setIsPublic] = useState(false);
  const [isDecrypted, setIsDecrypted] = useState(false);
  const [showDecryption, setShowDecryption] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [verification, setVerification] = useState({
    type: null,
    fieldData: null,
  });
  const [currentField, setCurrentField] = useState({});
  const [viewVerifiedData, setViewVerifiedData] = useState(false);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <Fragment>
      {showAction && (
        <RequestVerification
          verification={verification}
          currentField={currentField}
          show={showAction}
          setShowAction={setShowAction}
          showRequestButton={setShowAction}
        />
      )}
      {viewVerifiedData && (
        <ViewVerifiedDetails
          verifiedData={currentField}
          setShowAction={setViewVerifiedData}
        />
      )}

      <Container>
        <DocumentProfileHeader
          profileData={latestIpfsData}
          setVerification={setVerification}
          setShowAction={setShowAction}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
          setShowDecryption={setShowDecryption}
          setCurrentField={setCurrentField}
          isViewAssignedItem={true}
          ownerShipDetails={ownerShipDetails}
        />
        <SectionContainer>
          <SectionInnerContainer>
            <FlexSpaceBetweenContainer>
              <SubHeading primary>Document Content</SubHeading>
              {latestIpfsData?.description?.isPublic && (
                <VerifiedStatusContainer
                  isPublic={isPublic}
                  fieldData={latestIpfsData?.description}
                  fieldName="Description"
                  fieldId={{ id: "description" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  setShowDecryption={setShowDecryption}
                  isDecrypted={latestIpfsData?.description?.isPublic}
                  encryptedType={
                    latestIpfsData?.description?.data?.encryptionType
                  }
                  setCurrentField={setCurrentField}
                />
              )}
            </FlexSpaceBetweenContainer>
            {latestIpfsData?.description?.isPublic ? (
              <Preview
                className="preview"
                dangerouslySetInnerHTML={createMarkup(
                  latestIpfsData?.description?.data?.data
                )}
              />
            ) : (
              <EncryptedData
                fieldData={latestIpfsData?.description?.data?.data}
                setShowDecryption={setShowDecryption}
              />
            )}
          </SectionInnerContainer>
        </SectionContainer>
        <SectionContainer>
          <FlexColumnContainer>
            <FlexSpaceBetweenContainer>
              <SubHeading primary>Document Sections</SubHeading>
            </FlexSpaceBetweenContainer>
            <DocumentContainer
              productData={latestIpfsData?.stageData}
              setShowAction={setShowAction}
              setVerification={setVerification}
              setViewVerifiedData={setViewVerifiedData}
              setCurrentField={setCurrentField}
              setShowDecryption={setShowDecryption}
              isPublic={isPublic}
            />
          </FlexColumnContainer>
        </SectionContainer>
        <AdditionalDetails
          isDecrypted={isDecrypted}
          isPublic={isPublic}
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setCurrentField={setCurrentField}
        />
      </Container>
    </Fragment>
  );
};

export default ProviderViewDocument;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;

export const SectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;
export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1.5rem 1.5rem 3rem 1.5rem;
`;

export const Link = styled.a`
  color: #1e1b3b;
  max-width: 600px;
  word-break: break-word;
  font-weight: 500;
  @media (max-width: 1368px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
  }
`;

const Preview = styled.div`
  line-height: 1rem;
  margin-bottom: 1rem;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;
