import Divider from "@mui/material/Divider";
import React from "react";
import styled from "styled-components";
import { Text } from "../../Typography/Typo";
import { CustomButton } from "../../fields/Button/CustomButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LinkedIn from "../../../../assets/images/linkedin-icon.svg";
import { useSnackbar } from "notistack";

export const ProviderShareConfirm = ({
  setShowAction,
  data,
  link,
  message,
  title,
  currentUser,
}) => {
  // eslint-disable-next-line
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipBoard = async (val) => {
    try {
      await navigator.clipboard.writeText(val);
    } catch (err) {
      console.log(err);
    }
  };

  var html = data?.description?.data?.data;
  var div = document.createElement("p");
  div.innerHTML = html;

  return (
    <>
      <OverLayContainer onClick={() => setShowAction(false)} />
      <Container>
        <FlexContainer>
          <Text primary>{title}</Text>
          <Text primary>{`#${
            data.uniqueId
              ? data.uniqueId
              : currentUser.publicKey.substring(0, 15 - 3)
          }`}</Text>
        </FlexContainer>
        <Divider
          style={{
            background: "#36353511",
            width: "100%",
            margin: "0.5rem 0rem",
          }}
        />
        <ImageContainer>
          <Image src={data?.profileImage?.data?.data} alt="image" />
        </ImageContainer>
        <FlexContainer addPadding>
          <ColumnContainer>
            <Text primary>{data.name.data.data}</Text>
            <Text small lighter primary>
              {div?.innerText.length > 150
                ? div?.innerText.toString().substring(0, 100 - 3) + "..."
                : div?.innerText}
            </Text>
          </ColumnContainer>
        </FlexContainer>
        <FlexContainerAlignCenter
          style={{
            marginBottom: "0.5rem",
          }}
        >
          <IconContainer
            bg="#F5F8FA"
            href={`whatsapp://send?text=${link}`}
            target="_blank"
          >
            <Icon
              src="https://cdn.usbrandcolors.com/images/logos/whatsapp-logo.svg"
              alt="whatsapp"
            />
          </IconContainer>{" "}
          <IconContainer
            bg="#F5F8FA"
            href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
            target="_blank"
          >
            <Icon
              src="https://cdn.usbrandcolors.com/images/logos/facebook-logo.svg"
              alt="facebook"
            />
          </IconContainer>
          <IconContainer
            bg="#F5F8FA"
            href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
            target="_blank"
          >
            <Icon
              src="https://cdn.usbrandcolors.com/images/logos/instagram-logo.png"
              alt="instagram"
            />
          </IconContainer>
          <IconContainer
            bg="#F5F8FA"
            href={`https://twitter.com/intent/tweet?url=${link}`}
            target="_blank"
          >
            <Icon
              src="https://cdn.usbrandcolors.com/images/logos/twitter-logo.svg"
              alt="twitter"
            />
          </IconContainer>{" "}
          <IconContainer
            bg="#F5F8FA"
            href={`https://www.pinterest.com/pin/create/button/?url=${link}`}
            target="_blank"
          >
            <Icon
              src="https://cdn.usbrandcolors.com/images/logos/pinterest-logo.svg"
              alt="printrest"
            />
          </IconContainer>{" "}
          <IconContainer
            bg="#F5F8FA"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${link}`}
            target="_blank"
          >
            <Icon src={LinkedIn} alt="linked in" />
          </IconContainer>
        </FlexContainerAlignCenter>
        <CustomButton
          wide
          login
          onclick={() => {
            copyToClipBoard(link);
            enqueueSnackbar("URL Copied", {
              variant: "info",
            });
          }}
          icon={<ContentCopyIcon />}
          text={message}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 25rem;
  z-index: 999;
  height: 27.5rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

const FlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem, 0rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SmallText = styled.p`
  font-size: 0.7rem;
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
`;

export const ColumnLoadContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  height: 20rem;
  align-items: center;
`;

export const Gif = styled.img`
  margin-top: 5rem;
  width: 16rem;
  height: auto;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 45%;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  position: absolute;
  height: auto;
  transform: translate(-50%, -50%);
  top: 100%;
  left: 50%;
`;

const IconContainer = styled.a`
  position: relative;
  width: 2rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0.5rem;
  height: 2rem;
  background: ${(props) => props.bg};
  border-radius: 50%;
`;

const Icon = styled.img`
  width: 100%;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
`;

const FlexContainerAlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  width: 100%;
  padding: 2rem 0rem;
`;
