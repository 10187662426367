import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import ShareIcon from "@mui/icons-material/Share";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { CircularProgress } from "@mui/material";
import Web3 from "web3";

import ErrorBG from "../../assets/images/not-found.gif";
import ErrorBG2 from "../../assets/images/error.gif";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { DisabledView } from "../../university-pages/Certificates/IssueCertificateForm";
import { retrieveItem, claimItem, getUserSession } from "../../api/API";
import store from "../../redux/reducers";
import { ADD_IPFS_DATA } from "../../redux/constants/ActionTypes";
import { createEncryptionObject } from "../../utils/encryption";
import { AddToIPFS, GetIPFSData } from "../../services/IpfsService";
import { urlToHash } from "../../utils/ipfs";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import ActionConfirm from "../../components/shared/Form/FormComponents/ActionConfirm";
import { symmetricDecryption } from "../../services/encryptionService";
import { ShareConfirm } from "../../components/shared/Profile/ShareConfirm";
import SlideView from "../../university-pages/Certificates/SlideView";
import MultipleImageViewSlider from "../../components/shared/Form/StageForm/MultipleImageViewSlider";
import { ShareQr } from "../../components/shared/Profile/ShareQr";

const ClaimView = ({ publicView }) => {
  //eslint-disable-next-line
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [shareOpen, setShareOpen] = useState(false);
  const [openGenerateQr, setOpenGenerateQr] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector((state) => state.currentUser);
  const [tokenData, setTokenData] = useState();
  const [loading, setLoading] = useState(false);
  const [claimSubmitLoading, setClaimSubmitLoading] = useState(false);
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [showAction, setShowAction] = useState(false);
  const [urlName, setUrlName] = useState("polygonscan");
  const [blockchain, setBlockchain] = useState("");
  const [isMismatch, setIsMismatch] = useState(false);

  // Get the latestIpfsData
  const fetchLatestProfileData = async () => {
    if (currentUser?.latestIpfsUrl?.ipfsUrl) {
      const hash = urlToHash(currentUser?.latestIpfsUrl?.ipfsUrl);
      try {
        const data = await GetIPFSData(hash);
        setLatestIpfsData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Retrieving item API
  const retrieveItems = async () => {
    setLoading(true);
    fetchLatestProfileData();
    try {
      const response = await retrieveItem({ uniqueId: id });
      if (response.data.code === 200) {
        console.log(response.data.data[0]);
        setTokenData(response.data.data[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Claim API
  const claimApi = async (latestIpfsData, claimData) => {
    try {
      const response = await claimItem({
        body: claimData,
        path: {
          cId: tokenData.uniqueId,
        },
        token: localStorage.niftoken,
        query: {
          status: "approve",
        },
      });
      if (response.data.code === 202) {
        store.dispatch({
          type: ADD_IPFS_DATA,
          payload: latestIpfsData,
        });
        enqueueSnackbar("Successfully claimed", {
          variant: "success",
        });
      }
    } catch (e) {
      enqueueSnackbar("Failed to claim", {
        variant: "error",
      });
    }
  };

  // Handle claim button click
  const handleClaim = async (secretKey) => {
    setClaimSubmitLoading(true);
    try {
      if (currentUser?.latestIpfsUrl?.ipfsUrl) {
        var productObject = {};
        let encryptedObject = {};
        let data = {};

        productObject["itemName"] = tokenData?.itemName;
        productObject["itemImage"] = tokenData?.itemImage;
        productObject["description"] = tokenData?.description;
        productObject["issueDate"] = tokenData?.date;
        productObject["uniqueId"] = tokenData?.uniqueId;

        encryptedObject.verifiedBy = [tokenData?.senderPublicKey];
        encryptedObject.isPublic = true;
        for (const [key, value] of Object.entries(productObject)) {
          data[key] = {};
          data[key] = createEncryptionObject(
            "PUBLIC",
            value,
            currentUser.publicKey,
            currentUser.publicKey,
            currentUser.secretKey
          );
        }
        encryptedObject.data = data;
        // Check the achievements in latestIpfsData
        const achievements = latestIpfsData?.achievements
          ? latestIpfsData.achievements
          : [];
        // Push the new encryptedObject into achievements
        achievements.push(encryptedObject);
        // Set the latestIpfsData with achievements
        latestIpfsData.achievements = achievements;
        const productIpfsHash = await AddToIPFS(JSON.stringify(latestIpfsData));

        const evmPrivateKey = authUser.accounts[2].encryptedSecret;
        const privateKey = symmetricDecryption(evmPrivateKey, secretKey);
        let provider = "";
        if (blockchain === "MATIC") {
          provider = new Web3.providers.HttpProvider(
            "https://polygon-rpc.com/"
          );
        } else if (blockchain === "MUMBAI") {
          provider = new Web3.providers.HttpProvider(
            "https://rpc-mumbai.maticvigil.com/"
          );
        }
        const web3 = new Web3(provider);

        let signerAccount = web3.eth.accounts.privateKeyToAccount(privateKey);

        let contractAddress = "";
        if (blockchain === "MATIC") {
          contractAddress = "0x2173ad61D4eC8a4b1F288B0631b7b29972D23B0C"; //"0x44de733c9d0c3517906c9E8520DC6E0c78088cf9";
        } else {
          contractAddress = "0x1322D0894dF4355193625F7cdeFCC957BDD65ade";
        }

        let DeproABI = require("../../utils/abi/DeproNFT.json");
        const chainId = await web3.eth.getChainId();

        let domainData = {
          name: "MetaTransaction",
          version: "1",
          chainId: chainId, // Matic Testnet
          verifyingContract: contractAddress,
        };

        const domainType = [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ];

        const metaTransactionType = [
          { name: "nonce", type: "uint256" },
          { name: "from", type: "address" },
        ];

        let DeproNFT = new web3.eth.Contract(DeproABI, contractAddress);

        const nonce = await DeproNFT.methods
          .nonces(signerAccount.address)
          .call();

        let message = {};

        message.nonce = parseInt(nonce);
        message.from = signerAccount.address;

        const dataToSign = JSON.stringify({
          types: {
            EIP712Domain: domainType,
            MetaTransaction: metaTransactionType,
          },
          domain: domainData,
          primaryType: "MetaTransaction",
          message: message,
        });

        const hashedData = web3.utils.sha3(dataToSign);
        const signature = web3.eth.accounts.sign(
          hashedData,
          signerAccount.privateKey
        );

        let claimantSignature = {
          signR: signature.r,
          signS: signature.s,
          signV: signature.v,
          messageHash: signature.message,
          evmAddress: signerAccount.address,
        };

        const claimData = {
          publicKey: currentUser.publicKey,
          ipfsUrl: `https://niftron.infura-ipfs.io/ipfs/${productIpfsHash.ipfsHash}`,
          claimantSignature,
        };
        await claimApi(latestIpfsData, claimData);
      } else {
        enqueueSnackbar(`Please fill Profile data to proceed`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setClaimSubmitLoading(false);
      history.push("/dashboard/credentials");
    }
  };

  useEffect(() => {
    location?.state?.data ? setTokenData(location.state.data) : retrieveItems();
    //eslint-disable-next-line
  }, [location?.state?.data]);

  useEffect(() => {
    if (latestIpfsData && tokenData) {
      if (
        latestIpfsData &&
        latestIpfsData.fullName &&
        tokenData &&
        tokenData.fullName &&
        latestIpfsData.fullName.data?.data === tokenData.fullName
      ) {
        setIsMismatch(false);
      }

      if (
        latestIpfsData &&
        latestIpfsData.fullName &&
        tokenData &&
        tokenData.fullName &&
        latestIpfsData.fullName.data?.data !== tokenData.fullName
      ) {
        setIsMismatch(true);
      }
    }
  }, [latestIpfsData, tokenData]);

  useEffect(() => {
    switch (tokenData?.networkId) {
      case 1:
        setUrlName("etherscan");
        setBlockchain("ETHEREUM");
        break;
      case 4:
        setUrlName("rinkeby.etherscan");
        setBlockchain("RINKEBY");
        break;
      case 56:
        setUrlName("bscscan");
        setBlockchain("BSC");
        break;
      case 97:
        setUrlName("testnet.bscscan");
        setBlockchain("BSCTESTNET");
        break;
      case 137:
        setUrlName("polygonscan");
        setBlockchain("MATIC");
        break;
      case 80001:
        setUrlName("mumbai.polygonscan");
        setBlockchain("MUMBAI");
        break;
      default:
        setUrlName("polygonscan");
        setBlockchain("MATIC");
    }
  }, [tokenData?.networkId]);

  return (
    <Container>
      {shareOpen && (
        <ShareConfirm
          data={tokenData}
          message={
            tokenData?.type === "education"
              ? "Copy Token Link"
              : "Copy Token Link"
          }
          setShowAction={setShareOpen}
          currentUser={currentUser}
          title={
            tokenData?.type === "education"
              ? "Share your Token"
              : "Share your Token"
          }
          link={` http://explorer.deprofile.io/item/${tokenData?.uniqueId}`}
        />
      )}
      {openGenerateQr && (
        <ShareQr
          data={tokenData}
          message={"Copy Token Link"}
          setShowAction={setOpenGenerateQr}
          currentUser={currentUser}
          link={`http://qr.app.deprofile.io/tokens/${tokenData?.uniqueId}`}
        />
      )}
      {showAction && (
        <ActionConfirm
          successMessage={{
            message: "Requesting relayer to claim item",
            width: "17rem",
          }}
          warningMessage={"Are you sure you want to claim?"}
          loading={claimSubmitLoading}
          setShowAction={setShowAction}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          onSecretKey={handleClaim}
        />
      )}
      {(() => {
        if (loading) {
          return <InnerLoadingView style={{ marginTop: "1.5rem" }} />;
        } else if (tokenData === undefined) {
          return (
            <ErrorContainer>
              <Error bg={ErrorBG} width="500px">
                <SubHeading
                  primary
                >{`Token Not Found!`}</SubHeading>
              </Error>
              <Text primary>Looks like you're lost</Text>
            </ErrorContainer>
          );
        } else if (isMismatch) {
          return (
            <ErrorContainer>
              <Error bg={ErrorBG2} width="500px">
                <SubHeading primary>{`Profile Mismatch!`}</SubHeading>
              </Error>
              <Text primary>
                Oops! Looks like your fullname does not match, please contact
                your issuer and get it right.
              </Text>
            </ErrorContainer>
          );
        } else {
          return (
            <Fragment>
              <GridContainer style={{ marginTop: "2rem" }}>
                <ColumnContainer style={{ gap: "1rem" }}>
                  <ColumnContainer>
                    <Label style={{ marginBottom: "0.3rem" }}>
                      {tokenData?.type === "education"
                        ? "Token Name"
                        : "Token Name"}
                    </Label>
                    <DisabledView style={{ textTransform: "capitalize" }}>
                      {tokenData?.itemName}
                    </DisabledView>
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label style={{ marginBottom: "0.3rem" }}>
                      Description
                    </Label>
                    <DisabledView>{tokenData?.description}</DisabledView>
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label style={{ marginBottom: "0.3rem" }}>Blockchain</Label>
                    <DisabledView style={{ textTransform: "uppercase" }}>
                      {tokenData?.blockchain}
                    </DisabledView>
                  </ColumnContainer>
                  {tokenData?.email && (
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Awarded to
                      </Label>
                      <DisabledView>{tokenData?.email}</DisabledView>
                    </ColumnContainer>
                  )}

                  {tokenData?.fullName && (
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Receiver Full Name
                      </Label>
                      <DisabledView>{tokenData?.fullName}</DisabledView>
                    </ColumnContainer>
                  )}
                  {tokenData?.name && (
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Receiver Name
                      </Label>
                      <DisabledView>{tokenData?.name}</DisabledView>
                    </ColumnContainer>
                  )}
                  {tokenData?.txnHashes?.mintedTxnHash && (
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Mint Txn Hash
                      </Label>
                      <DisabledView>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`https://${urlName}.com/tx/${tokenData?.txnHashes?.mintedTxnHash}`}
                        >
                          {tokenData?.txnHashes?.mintedTxnHash}
                        </a>
                      </DisabledView>
                    </ColumnContainer>
                  )}
                  {tokenData?.txnHashes?.claimedTxnHash && (
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Claim Txn Hash
                      </Label>
                      <DisabledView>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`https://${urlName}.com/tx/${tokenData?.txnHashes?.claimedTxnHash}`}
                        >
                          {tokenData?.txnHashes?.claimedTxnHash}
                        </a>
                      </DisabledView>
                    </ColumnContainer>
                  )}
                </ColumnContainer>

                <ImageWrapper>
                  <Image
                    alt="Token"
                    src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      tokenData?.itemImage
                    )}`}
                  />
                </ImageWrapper>
              </GridContainer>

              <ButtonWrapper>
                {tokenData?.status === 0 &&
                  !tokenData.profilePublicKey &&
                  !isMismatch && (
                    <CustomButton
                      login
                      icon={
                        claimSubmitLoading && <CircularProgress size={25} />
                      }
                      disabled={claimSubmitLoading}
                      text={
                        tokenData?.type === "education"
                          ? "Claim Token"
                          : "Claim Token"
                      }
                      acceptClass
                      onclick={() => {
                        setShowAction(true);
                      }}
                    />
                  )}

                {!publicView && (
                  <Fragment>
                    <CustomButton
                      login
                      text="Back"
                      onclick={() => history.push("/dashboard/credentials")}
                    />
                    {tokenData?.isClaimed && (
                      <CustomButton
                        login
                        icon={<ShareIcon />}
                        text={
                          tokenData?.type === "education"
                            ? "Share Token"
                            : "Share Token"
                        }
                        onclick={() => setShareOpen(true)}
                      />
                    )}
                    <CustomButton
                      login
                      icon={<QrCodeIcon />}
                      text="Generate QR"
                      onclick={() => setOpenGenerateQr(true)}
                    />
                  </Fragment>
                )}
              </ButtonWrapper>
            </Fragment>
          );
        }
      })()}
    </Container>
  );
};

export default ClaimView;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
  min-height: 24rem;
  margin-bottom: 2rem;
`;

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 1rem;
  justify-content: center;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  color: #080036;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  gap: 2rem;
  display: flex;
  justify-content: flex-end;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  justify-self: end;
  border-radius: 5px;
  text-align: center;
  display: block;
`;

export const Error = styled.div`
  background-image: url(${(props) => props.bg});
  height: ${(props) => props.width};
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
`;

export const ErrorContainer = styled.div`
  display: flex;
  height: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
