import { IconButton } from "@mui/material";
import React, { useState } from "react";
import EditForm from "../EditForms/EditForm";
import { TextField } from "../Form/FormComponents/TextField";
import { ColumnContainer } from "../Form/ProductFormContainer";
import MultipleImageViewSlider from "../Form/StageForm/MultipleImageViewSlider";
import {
  FlexSpaceBetweenContainer,
  SectionInnerContainer,
} from "../ProductProfile/ProductProfile";
import { SubHeading, Text } from "../Typography/Typo";
import { IconWrapper, SectionContainer } from "./UserProfileHeader";
import VerifiedStatusContainer from "./VerifiedStatusContainer";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CustomDetailsCard from "../Form/StageForm/CustomDetailsCard";

const AdditionalDetails = ({
  isPublic,
  profileData,
  setShowAction,
  setVerification,
  setShowDecryption,
  setShowEncryption,
  setCurrentField,
  isDecrypted,
  decryptType,
  setShowCustomEdit,
  setEditType,
  isViewAssignedItem,
  showAddIcon,
  setOpenEditedDetails,
  setEditedDetails,
  profileType,
}) => {
  const Hash = "*";

  var dataListfieldKeys =
    profileData?.customData?.length > 0 &&
    profileData?.customData?.map((data) => {
      const keyData = Object.keys(data?.data);
      return keyData;
    });

  return (
    <>
      <SectionContainer>
        <SectionInnerContainer>
          <FlexSpaceBetweenContainer>
            <SubHeading primary>
              {profileType && profileType === 4
                ? "Document Meta Data"
                : "Additional Details"}
            </SubHeading>
            {!showAddIcon && (
              <IconWrapper style={{ backgroundColor: "#c7c7c72f" }}>
                <IconButton
                  onClick={() => {
                    setEditType("");
                    if (!isDecrypted) {
                      decryptType("all");
                      setShowDecryption(true);
                      setEditType("customEdit");
                    } else {
                      setShowCustomEdit((value) => !value);
                    }
                  }}
                >
                  {profileData?.customData?.length === 0 ? (
                    <AddIcon
                      style={{ color: "#8a8a8a" }}
                      sx={{ fontSize: 20 }}
                    />
                  ) : (
                    <EditIcon
                      style={{ color: "#8a8a8a" }}
                      sx={{ fontSize: 20 }}
                    />
                  )}
                </IconButton>
              </IconWrapper>
            )}
          </FlexSpaceBetweenContainer>
          {dataListfieldKeys &&
            dataListfieldKeys?.length > 0 &&
            dataListfieldKeys?.map((fieldName, key) => {
              if (
                Array.isArray(
                  profileData?.customData[key].data[fieldName[0]].data
                )
              ) {
                return (
                  <ColumnContainer span={"true"} key={key}>
                    <MultipleImageViewSlider
                      fieldName={fieldName[0]}
                      data={
                        profileData?.customData[key].data[fieldName[0]].data
                      }
                    />
                  </ColumnContainer>
                );
              } else if (
                profileData?.customData[key].data[fieldName[0]].data.includes(
                  "ipfs"
                )
              ) {
                return (
                  <ColumnContainer span={"true"} key={key}>
                    {/* <MultipleImageViewSlider
                      id={key}
                      viewPage={true}
                      isPublic={isPublic}
                      setShowAction={setShowAction}
                      setVerification={setVerification}
                      setShowDecryption={setShowDecryption}
                      setShowEncryption={setShowEncryption}
                      setCurrentField={setCurrentField}
                      fieldName={fieldName[0]}
                      profileData={profileData?.customData[key]}
                      data={profileData?.customData[key].data[
                        fieldName[0]
                      ].data?.split(",")}
                      setOpenEditedDetails={setOpenEditedDetails}
                      setEditedDetails={setEditedDetails}
                    /> */}
                    <CustomDetailsCard
                      id={key}
                      viewPage={true}
                      isPublic={isPublic}
                      setShowAction={setShowAction}
                      setVerification={setVerification}
                      setShowDecryption={setShowDecryption}
                      setShowEncryption={setShowEncryption}
                      setCurrentField={setCurrentField}
                      fieldName={fieldName[0]}
                      profileData={profileData?.customData[key]}
                      data={profileData?.customData[key].data[
                        fieldName[0]
                      ].data?.split(",")}
                      setOpenEditedDetails={setOpenEditedDetails}
                      setEditedDetails={setEditedDetails}
                    />
                  </ColumnContainer>
                );
              } else {
                return (
                  <TextField
                    icon={
                      showAddIcon && profileData?.customData[key].isPublic ? (
                        <VerifiedStatusContainer
                          status={0}
                          isPublic={isPublic}
                          fieldData={profileData?.customData[key]}
                          fieldId={{ id: key, name: "customData" }}
                          fieldName={fieldName[0]}
                          setShowAction={setShowAction}
                          setVerification={setVerification}
                          setShowDecryption={setShowDecryption}
                          setShowEncryption={setShowEncryption}
                          isDecrypted={profileData?.customData[key].isPublic}
                          setCurrentField={setCurrentField}
                          encryptedType={
                            profileData?.customData[key].data[fieldName[0]]
                              .encryptionType
                          }
                          setOpenEditedDetails={setOpenEditedDetails}
                          setEditedDetails={setEditedDetails}
                        />
                      ) : !showAddIcon ? (
                        <VerifiedStatusContainer
                          status={0}
                          isPublic={isPublic}
                          fieldData={profileData?.customData[key]}
                          fieldId={{ id: key, name: "customData" }}
                          fieldName={fieldName[0]}
                          setShowAction={setShowAction}
                          setVerification={setVerification}
                          setShowDecryption={setShowDecryption}
                          setShowEncryption={setShowEncryption}
                          isDecrypted={profileData?.customData[key].isPublic}
                          setCurrentField={setCurrentField}
                          encryptedType={
                            profileData?.customData[key].data[fieldName[0]]
                              .encryptionType
                          }
                          setOpenEditedDetails={setOpenEditedDetails}
                          setEditedDetails={setEditedDetails}
                        />
                      ) : (
                        <></>
                      )
                    }
                    disabled
                    key={key}
                    label={
                      fieldName[0]
                        .toString()
                        .replace(/([A-Z])/g, " $1")
                        .substring(0, 1)
                        .toUpperCase() +
                      fieldName[0]
                        .toString()
                        .replace(/([A-Z])/g, " $1")
                        .substring(1)
                    }
                    value={
                      profileData?.customData[key].isPublic === true
                        ? profileData?.customData[key].data[fieldName[0]]?.data
                        : Hash.repeat(
                            profileData?.customData[key].data[fieldName[0]]
                              ?.data.length
                          )
                    }
                  />
                );
              }
            })}
        </SectionInnerContainer>
      </SectionContainer>
    </>
  );
};

export default AdditionalDetails;
