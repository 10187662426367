import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";

import VerificationStatusView from "../VerificationViews/VerificationStatusView";
import VerificationAccordion from "../VerificationViews/VerificationAccordion";
import VerificationStepper from "../VerificationViews/VerificationStepper";
import IpfsDataAccodion from "./IpfsDataAccodion";
import { Text } from "../Typography/Typo";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";

export const ViewCvHistory = ({
  setShowAction,
  setCvHistoryIpfsUrl,
  cvHitory,
}) => {
  // const [expanded, setExpanded] = useState(false);
  const latestIpfsUrl = useSelector(
    (state) => state.currentUser.latestIpfsUrl.ipfsUrl
  );
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  return (
    <Fragment>
      <OverLayContainer
        onClick={() => {
          setShowAction(false);
        }}
      />

      <Container>
        <FlexContainer>
          <Text primary>Audit Trail</Text>
        </FlexContainer>

        <OverFlowContainer>
          <List>
            {cvHitory.map((item, key) => {
              return (
                <LisComponent>
                  <ListItem
                    key={key}
                    role={undefined}
                    dense
                    button
                    onClick={() => {
                      if (latestIpfsUrl === item.ipfsUrl) {
                        setCvHistoryIpfsUrl("");
                        setShowAction(false);
                      } else {
                        setCvHistoryIpfsUrl(item.ipfsUrl);
                        setShowAction(false);
                      }
                    }}
                  >
                    <ListItemText
                      style={{ marginTop: "20px" }}
                      primary={`Version ${key}`}
                      secondary={moment(item?.createdAt).format("LLL")}
                    />
                    {item?.txnHash && (
                      <ListItemSecondaryAction>
                        <ListItemButton
                          style={{ display: "flex", flexDirection: "row" }}
                          onClick={() => {
                              window.open(
                                `https://polygonscan.com/tx/${item?.txnHash}`
                              );
                            
                          }}
                        >
                          <ListItemText
                            primary={
                              item?.txnHash.toString().substring(0, 25 - 3) +
                              "..."
                            }
                            style={{ color: "#0000EE", cursor: "pointer" }}
                          />
                        </ListItemButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                  <Divider />
                </LisComponent>
              );
            })}
          </List>

          {/* {Object.keys(latestIpfsData).map((key, index) => {
            if (key !== "profileType") {
              return (
                <IpfsDataAccodion
                  key={key}
                  itemName={key}
                  item={latestIpfsData[key]}
                  handleChange={handleChange}
                  expanded={expanded}
                  index={index}
                  setCvHistoryIpfsUrl={setCvHistoryIpfsUrl}
                  setShowAction={setShowAction}
                />
              );
            }
          })} */}
        </OverFlowContainer>
      </Container>
    </Fragment>
  );
};

export const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 40%;
  z-index: 999;
  height: auto;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
    padding: 1.5rem;
  }
  @media (max-width: 1368px) {
    width: 80%;
  }
`;

const LisComponent = styled.div`
  // padding: 0.5rem;
`;

const OverFlowContainer = styled.div`
  max-height: 30rem;
  overflow-y: auto;
  padding: 0rem 1rem 2rem 1rem;
  width: 93%;
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

export const SmallText = styled.p`
  font-size: 0.7rem;
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
`;

export const ColumnLoadContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  height: 20rem;
  align-items: center;
`;

export const Gif = styled.img`
  margin-top: 5rem;
  width: 16rem;
  height: auto;
`;

const FlexContainer = styled.div`
  width: 93%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
`;
