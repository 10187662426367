import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { useSnackbar } from "notistack";
import DOMPurify from "dompurify";
import { useMediaQuery } from "@mui/material";

import { getUserSession } from "../../../services/UserManagement";
import PrivacyToggle from "../fields/Button/PrivacyToggle";
import {
  Label,
  TextField,
  Container as DropContainer,
  InputContainer,
} from "../Form/FormComponents/TextField";
import {
  ButtonWrapper,
  ColumnContainer,
  phoneNoValidation,
} from "../Form/Web3ResumeContainer";
import { FlexSpaceBetweenContainer } from "../Profile/UserProfile";
import { SubHeading } from "../Typography/Typo";
import DropzoneComponent from "../fields/Dropzone/DropzoneComponent";
import { CustomButton } from "../fields/Button/CustomButton";
import { AddImageToIPFS, AddToIPFS } from "../../../services/IpfsService";
import { useStateValue } from "../../../context/StateProvider";
import { createCVObject, oldCreateCVObject } from "../../../utils/cvObject";
import * as API from "../../../api/API";
import { jwtDecode } from "../../../utils/jwt";
import ActionConfirm from "../Form/FormComponents/ActionConfirm";
import { getArrayData } from "../../../utils/editForm";
import store from "../../../redux/reducers";
import { ADD_USER_DETAILS } from "../../../redux/constants/ActionTypes";
import {
  symmetricDecryption,
  symmetricEncryption,
} from "../../../utils/encryption";
import TextEditor from "../TextEditor/TextEditor";
import { urlToHash } from "../../../utils/ipfs";
import Web3 from "web3";
import { getSignature } from "../../../utils/getSingature";

const DocumentEdit = ({ profileData, setEditOpen }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [authCreator, setAuthCreator] = useState(() => API.getCreatorSession());
  let defaultImage = [{ preview: profileData?.documentImage?.data?.data }];
  const [{ privacyData }, dispatch] = useStateValue();
  //eslint-disable-next-line
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [loading, setLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [files, setFiles] = useState(defaultImage);
  const [name, setName] = useState(profileData?.documentName?.data?.data);
  const [description, setDescription] = useState(
    profileData?.description?.data?.data
  );
  const [editorState, onEditorStateChange] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(profileData?.description?.data?.data)
      )
    )
  );
  const [errors, setErrors] = useState();
  const [phoneNumber, setPhoneNumber] = useState(
    profileData?.phoneNumber?.data?.data
  );
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const matches = useMediaQuery("(max-width:1368px)");

  function handleBasicEdit(e) {
    e.preventDefault();
    setShowAction(true);
  }

  var html = description;
  var div = document.createElement("p");
  div.innerHTML = html;

  const ipfsHash = urlToHash(currentUser.latestIpfsUrl.ipfsUrl);

  const dispatchProduct = async (secretKey) => {
    setLoading(true);

    try {
      // Set editedBy array empty
      Object.keys(profileData).forEach((key) => {
        if (key !== "previousIpfsHash") {
          if (
            key === "customData" ||
            key === "achievements" ||
            key === "educationData" ||
            key === "workData" ||
            key === "techStack" ||
            key === "stageData"
          ) {
            profileData[key].forEach((item) => {
              item.editedBy = [];
            });
          } else {
            profileData[key].editedBy = [];
          }
        }
      });

      const arr = [
        {
          editorPublicKey: localStorage.getItem("providerToken")
            ? authCreator.publicKey
            : currentUser.publicKey,
          editorAlias: localStorage.getItem("providerToken")
            ? authCreator.alias
            : currentUser.publicKey,
          createdAt: Date.now(),
          ipfsHash: "",
        },
      ];

      privacyData.forEach((item) => {
        // For documentName
        if (item?.documentName !== undefined) {
          // Only for privacy
          if (
            profileData.documentName.isPublic === item.documentName &&
            profileData.documentName.data.data === name
          ) {
            profileData.documentName.isPublic = !item.documentName;
            if (item.documentName) {
              profileData.documentName.data.encryptionType = "SYMMETRIC";
              profileData.documentName.data.data = symmetricEncryption(
                name,
                secretKey
              );
            }

            profileData.documentName.editedBy = arr;
          } else if (
            (profileData.documentName.isPublic === item.documentName &&
              profileData.documentName.data.data !== name) ||
            profileData.documentName.data.data !== name
          ) {
            profileData.documentName.isPublic = !item.documentName;
            profileData.documentName.verifiedBy = [];

            if (item.documentName) {
              profileData.documentName.data.encryptionType = "SYMMETRIC";
              profileData.documentName.data.data = symmetricEncryption(
                name,
                secretKey
              );
            } else {
              profileData.documentName.data.data = name;
            }

            profileData.documentName.editedBy = arr;
          }
        }

        // For description
        if (item?.description !== undefined) {
          if (
            profileData.description.isPublic === item.description &&
            profileData.description.data.data === description
          ) {
            profileData.description.isPublic = !item.description;
            if (item.description) {
              profileData.description.data.encryptionType = "SYMMETRIC";
              profileData.description.data.data = symmetricEncryption(
                description,
                secretKey
              );
            }

            profileData.description.editedBy = arr;
          } else if (
            (profileData.description.isPublic === item.description &&
              profileData.description.data.data !== description) ||
            profileData.description.data.data !== description
          ) {
            profileData.description.isPublic = !item.description;
            profileData.description.verifiedBy = [];
            if (item.description) {
              profileData.description.data.encryptionType = "SYMMETRIC";
              profileData.description.data.data = symmetricEncryption(
                description,
                secretKey
              );
            } else {
              profileData.description.data.data = description;
            }

            profileData.description.editedBy = arr;
          }
        }
      });

      profileData.previousIpfsHash = ipfsHash;

      // Get logged in user
      const user = jwtDecode();

      const { blockchain, signatureObj } = await getSignature(
        currentUser,
        authUser,
        secretKey
      );

      // add product to ipfs
      const productIpfsHash = await AddToIPFS(JSON.stringify(profileData));

      const response = await API.addCv({
        path: { id: user.publicKey },
        token: localStorage.niftoken,
        body: {
          latestIpfsUrl: {
            ipfsUrl: `https://niftron.infura-ipfs.io/ipfs/${productIpfsHash.ipfsHash}`,
            timestamp: Date.now(),
          },
          ipfsHash: productIpfsHash.ipfsHash,
          // documentImage: previewImageUrl,
          profileType: 4,
          network: blockchain,
          signature: signatureObj,
        },
      });

      if (response?.data?.code === 201) {
        const userData = await API.me({
          token: localStorage.niftoken,
        });
        if (userData?.data?.code === 200) {
          store.dispatch({
            type: ADD_USER_DETAILS,
            payload: userData?.data?.data[0],
          });
          enqueueSnackbar("Document Meta Data Edited", {
            variant: "success",
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFiles([]);
      setLoading(false);
      setEditOpen(false);
      setShowAction(false);
      history.push("/dashboard/my-profile");
    }
  };

  if (showAction) {
    return (
      <ActionConfirm
        successMessage={{
          message: "Making changes in IPFS",
          width: "12rem",
        }}
        warningMessage={"Are you sure you want to edit?"}
        loading={loading}
        setShowAction={setShowAction}
        authTypeInput={authUser.authType}
        publicKey={authUser.publicKey}
        encryptedSecret={authUser.encryptedSecret}
        onSecretKey={dispatchProduct}
      />
    );
  } else {
    return (
      <>
        <OverLayContainer onClick={() => setEditOpen(false)} />
        <Container>
          <OverFlowContainer>
            <GridContainer
              id="edit-header-document-form"
              onSubmit={handleBasicEdit}
            >
              <FlexSpaceBetweenContainer
                style={{
                  width: "95%",
                  position: "absolute",
                  left: "0",
                  top: "0",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  zIndex: "9999",
                }}
              >
                <SubHeading style={{ padding: "2rem 3rem" }} primary>
                  Edit Document Meta Data
                </SubHeading>
              </FlexSpaceBetweenContainer>
              <TextField
                disabled={profileData?.documentName?.verifiedBy.find(
                  (item) => item?.verificationStatus === 0
                )}
                label="Document Name *"
                required
                value={name}
                onChange={setName}
                placeholder="Document Name "
                id="documentName"
                form="unProductForm"
                name="documentName"
                type="text"
                maxLength="100"
                color="#FF613F"
                helper={
                  profileData?.documentName?.verifiedBy.find(
                    (item) => item?.verificationStatus === 0
                  ) && "undergoing verification"
                }
                icon={<PrivacyToggle fieldName="documentName" _id="1523" />}
              />

              <TextEditor
                placeholder="Document Content *"
                fieldName="Document Content"
                editorState={editorState}
                setDescription={setDescription}
                setEditorState={onEditorStateChange}
                disabled={profileData?.description?.verifiedBy.find(
                  (item) => item?.verificationStatus === 0
                )}
                icon={<PrivacyToggle fieldName="description" _id="519" />}
              />
            </GridContainer>
            <ColumnContainer
              style={{
                width: "100%",
                position: "absolute",
                right: "0",
                bottom: "0",
                backgroundColor: "#fff",
                borderRadius: "10px",
                zIndex: "9999",
              }}
            >
              <ButtonWrapper style={{ gap: "1rem", padding: "2rem 3rem" }}>
                <CustomButton
                  login
                  text="Cancel"
                  rejectClass
                  type="button"
                  onclick={() => setEditOpen(false)}
                />
                <CustomButton
                  login
                  text="Update Data"
                  type="submit"
                  form="edit-header-document-form"
                  disabled={loading}
                />
              </ButtonWrapper>
            </ColumnContainer>
          </OverFlowContainer>
        </Container>
      </>
    );
  }
};

export default DocumentEdit;

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  max-width: 60rem;
  z-index: 999;
  height: 35rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1468px) {
    width: 80%;
    padding: 2rem 0rem;
  }
  @media (max-width: 768px) {
    width: 80%;
    padding: 2rem 0.5rem;
  }
`;

const OverFlowContainer = styled.div`
  overflow: auto;
  display: flex;
  padding: 0rem 1.5rem 2rem 1.5rem;
  margin: 1rem;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0rem 0.5rem 1rem 0.5rem;
    margin: 0rem;
  }
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b558f; /* Add the blur effect */
`;

export const GridContainer = styled.form`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  margin: 4.5rem 0rem;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;
