import axios from "axios";
import jwt from "jsonwebtoken";

export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
// export const PROFILES_URL = "http://localhost:5000/profiles/stage/"//process.env.REACT_APP_PROFILES_URL;
export const PROFILES_URL = process.env.REACT_APP_PROFILES_URL;

export const VERIFICATIONS_URL = process.env.REACT_APP_VERIFICATIONS_URL;
export const TOKENS_URL = process.env.REACT_APP_TOKENS_URL;
export const WORKFLOWS_URL = process.env.REACT_APP_WORKFLOWS_URL;
export const CREDENTIALPROVIDER_URL =
  process.env.REACT_APP_CREDENTIALPROVIDER_URL;
export const JOBS_URL = process.env.REACT_APP_JOBS_URL;
export const NIFTRON_URL = process.env.REACT_APP_NIFTRON_API;

// AUTH API //
export async function register(data) {
  try {
    const response = await axios.post(AUTH_URL + "register", data.body);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function me(data) {
  try {
    const response = await axios.get(AUTH_URL + "me", {
      headers: {
        "x-auth-token": data.token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function passwordReset(data) {
  try {
    const response = await axios.post(AUTH_URL + "passwordReset", data.body);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function checkUser(data) {
  try {
    const response = await axios.get(
      AUTH_URL + data.aId + "?profileType=" + data.query.profileType
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getObject(data) {
  try {
    const response = await axios.get(AUTH_URL + "object/" + data.aId);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function searchUser(data) {
  try {
    const response = await axios.get(
      AUTH_URL + "search?key=" + data.query.key + "&type=" + data.query.type
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

// PROFILE API //
export async function addCv(data) {
  try {
    const response = await axios.post(
      PROFILES_URL + data.path.id + "/cvs",
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function updateProfile(data) {
  try {
    const response = await axios.put(PROFILES_URL + data.path.id, data.body, {
      headers: {
        "x-auth-token": data.token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveItem(data) {
  try {
    const response = await axios.get(PROFILES_URL + data.uniqueId + "/item");
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function claimItem(data) {
  try {
    const response = await axios.put(
      PROFILES_URL + data.path.cId + "/review?status=" + data.query.status,
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveClaimedItem(data) {
  try {
    const response = await axios.get(
      PROFILES_URL + data.path.pId + "/claimedItem",
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function scanItem(data) {
  try {
    const response = await axios.post(PROFILES_URL + "scanItem", data.body);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveScannedItems(data) {
  try {
    const response = await axios.get(
      PROFILES_URL + data.path.pId + "/scannedItems"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function searchProfile(data) {
  try {
    const response = await axios.get(
      PROFILES_URL + "search?key=" + data.query.key
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

// CREDENTIAL PROVIDER API //

export async function retrieveCredentialProvider(data) {
  try {
    const response = await axios.get(
      CREDENTIALPROVIDER_URL + data.path.id + "/retrieve"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function updateCredentialProvider(data) {
  try {
    const response = await axios.put(
      CREDENTIALPROVIDER_URL + data.path.id,
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function issueItem(data) {
  try {
    const response = await axios.post(CREDENTIALPROVIDER_URL, data.body, {
      headers: {
        "x-auth-token": data.token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function searchCredentialProvider(data) {
  try {
    const response = await axios.get(
      CREDENTIALPROVIDER_URL + "search?key=" + data.query.key
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveIssuedCredentials(data) {
  try {
    const response = await axios.get(CREDENTIALPROVIDER_URL + data.path.id, {
      headers: {
        "x-auth-token": data.token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function addProfile(data) {
  try {
    const response = await axios.put(
      CREDENTIALPROVIDER_URL + data.path.id + "/addProfile",
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

// JOBS API //
export async function createJobPosting(data) {
  try {
    const response = await axios.post(JOBS_URL, data.body);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function fetchJobs(data) {
  try {
    const response = await axios.get(
      JOBS_URL + "?col=" + data.query.col + "&key=" + data.query.key
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveApplications(data) {
  try {
    const response = await axios.get(JOBS_URL + data.path.id + "/application");
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function applyJob(data) {
  try {
    const response = await axios.post(JOBS_URL + "job", data.body);
    return response;
  } catch (error) {
    console.error(error);
  }
}

// VERIFICATIONS API //
export async function requestVerification(data) {
  try {
    const response = await axios.post(
      VERIFICATIONS_URL + data.path.vId,
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveVerifications(data) {
  try {
    const response = await axios.get(
      VERIFICATIONS_URL + data.path.vId + "/?type=" + data.query.type,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function reviewVerification(data) {
  try {
    const response = await axios.post(
      VERIFICATIONS_URL + data.path.vId + "/review?status=" + data.query.status,
      data.body
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function assignQr(data) {
  try {
    const response = await axios.post(VERIFICATIONS_URL + "viewQr", data.body);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveAssignedProducts(data) {
  try {
    const response = await axios.get(
      VERIFICATIONS_URL + data.path.vId + "/products?type=" + data.query.type
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

// TOKENS API //
export async function createToken(data) {
  try {
    const response = await axios.post(TOKENS_URL, data.body);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveTokens(data) {
  try {
    const response = await axios.get(TOKENS_URL + data.path.tId);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function createTransaction(data) {
  try {
    const response = await axios.put(TOKENS_URL + "transaction", data.body);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveTransactions(data) {
  try {
    const response = await axios.get(
      TOKENS_URL + data.path.tId + "/transaction "
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

// WORKFLOWS API //
export async function createWorkflow(data) {
  try {
    const response = await axios.post(
      WORKFLOWS_URL + data.path.wId,
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveWorkflow(data) {
  try {
    const response = await axios.get(WORKFLOWS_URL + data.path.wId);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveWorkflows(data) {
  try {
    const response = await axios.get(
      WORKFLOWS_URL + data.path.wId + "/workflows",
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveTemplates(data) {
  try {
    const response = await axios.get(WORKFLOWS_URL + "templates");
    return response;
  } catch (error) {
    console.error(error);
  }
}

// GET USER SESSION //
export function getUserSession() {
  if (localStorage.getItem("niftoken") !== null) {
    const decodedToken = jwt.decode(localStorage.getItem("niftoken"));
    if (decodedToken === null) {
      return null;
    } else {
      return decodedToken;
    }
  }
}

export function getCreatorSession() {
  if (localStorage.getItem("providerToken") !== null) {
    const decodedToken = jwt.decode(localStorage.getItem("providerToken"));
    if (decodedToken === null) {
      return null;
    } else {
      return decodedToken;
    }
  }
}

// NIFTRON API //

export async function getNiftronUser(data) {
  try {
    const response = await axios.get(NIFTRON_URL + "/users/" + data.path.id);
    return response;
  } catch (error) {
    console.error(error);
  }
}
