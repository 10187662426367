import React, { useState } from "react";
import styled from "styled-components";
import { Label, TextField } from "../FormComponents/TextField";
import { Column } from "../Web3ResumeContainer";
import { useSnackbar } from "notistack";
import { useStateValue } from "../../../../context/StateProvider";
import { Button, CircularProgress, useMediaQuery } from "@mui/material";
import uuid from "react-uuid";
import DropzoneComponent from "../../fields/Dropzone/DropzoneComponent";
import { AddImageToIPFS } from "../../../../services/IpfsService";
import CustomView from "../../CustomTextfieldModal/CustomView";
import CustomTextFieldModal from "../../CustomTextfieldModal/CustomTextFieldModal";
import { SubHeading } from "../../Typography/Typo";
import MultipleImageViewSlider from "./MultipleImageViewSlider";
import Select from "react-select";
import TextEditor from "../../TextEditor/TextEditor";
import PrivacyToggle from "../../fields/Button/PrivacyToggle";

const privacyOptions = [
  { value: true, label: "Public" },
  { value: false, label: "Private" },
];

const DocumentStageForm = () => {
  const [openCustomModal, setCustomModalOpen] = useState(false);
  const [editorState, onEditorStateChange] = useState();
  const [description, setDescription] = useState("");
  var currentDateTime = Date.now();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [{ stageCustomData }, dispatch] = useStateValue();
  const matches = useMediaQuery("(max-width:1368px)");
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState("");
  const [privacyCustom, setPrivacyCustom] = useState(privacyOptions[0]);

  var dataListfieldKeys =
    stageCustomData.length > 0 &&
    stageCustomData.map((data) => {
      const keyData = Object.keys(data);
      return keyData;
    });

  var customDataListToMap =
    stageCustomData.length > 0 &&
    stageCustomData.map((data) => {
      return data;
    });

  const handleStageFieldSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    stageCustomData.map((e) => {
      e.isPublic = privacyCustom.value;
    });

    let stageBody = {
      id: uuid(),
      stageName: name,
      stageDescription: description,
      addedDate: currentDateTime,
      customStageData: stageCustomData,
      isPublic: privacyCustom.value,
    };

    try {
      dispatch({
        type: "ADD_PRODUCTS",
        item: stageBody,
      });
      enqueueSnackbar(`Document Document ${name} added`, {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setName("");
      setDescription("");
      onEditorStateChange(undefined);
      setPrivacyCustom(privacyOptions[0]);
      dispatch({
        type: "CLEAR_STAGE_FIELDS",
      });
      dispatch({
        type: "CLEAR_STAGE_CUSTOM_DATA_FIELDS",
      });
      setLoading(false);
    }
  };

  return (
    <OuterContainer>
      <CustomTextFieldModal
        title="Add Additional Document Meta Data"
        formId="document-data-form"
        setCustomModalOpen={setCustomModalOpen}
        openCustomModal={openCustomModal}
        stageType
      />
      <ContainerDiv>
        <TextField
          label="Document Stage Name *"
          placeholder="Document Stage Name "
          id="stageName-stage"
          onChange={setName}
          style={{ gridColumn: `${!matches ? "1/3" : "unset"}` }}
          value={name}
          name="stageName"
          type="text"
          maxLength="200"
        />
        <TextEditor
          placeholder="Document Stage Description *"
          fieldName="Document Stage Description"
          editorState={editorState}
          setDescription={setDescription}
          setEditorState={onEditorStateChange}
          icon={<PrivacyToggle fieldName="description" _id="519" />}
        />
        <Column span={!matches ? true : false}>
          <Label>Private / Public</Label>
          <Select
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                outline: "none",
                text: "orangered",
                primary25: "#2f3a6039",
                primary: " #2f3a60a7",
                neutral0: "#c3d0e1",
                boxShadow: "none",
              },
            })}
            name="isPublic"
            className="basic-multi-select"
            classNamePrefix="select"
            defaultValue={privacyCustom}
            options={privacyOptions}
            value={privacyCustom}
            onChange={setPrivacyCustom}
          />
        </Column>
        <SubHeading
          primary
          style={{ gridColumn: `${!matches ? "1/3" : "unset"}` }}
        >
          Add Section Meta Data
        </SubHeading>
        {dataListfieldKeys &&
          dataListfieldKeys.length > 0 &&
          dataListfieldKeys.map((fieldName, key) => {
            if (Array.isArray(customDataListToMap[key][fieldName[0]])) {
              return (
                <ColumnContainer span={"true"}>
                  <MultipleImageViewSlider
                    fieldName={fieldName[0]}
                    data={customDataListToMap[key][fieldName[0]]}
                  />
                </ColumnContainer>
              );
            } else {
              return (
                <TextField
                  key={key}
                  disabled
                  label={
                    fieldName[0]
                      .toString()
                      .replace(/([A-Z])/g, " $1")
                      .substring(0, 1)
                      .toUpperCase() +
                    fieldName[0]
                      .toString()
                      .replace(/([A-Z])/g, " $1")
                      .substring(1)
                  }
                  value={customDataListToMap[key][fieldName[0]]}
                />
              );
            }
          })}
        <CustomView
          style={{ gridColumn: `${!matches ? "1/3" : "unset"}` }}
          setCustomModalOpen={setCustomModalOpen}
        />
        <ButtonWrapper
          style={{
            girdColumn: `${!matches ? "1/3" : "unset"}`,
            margin: "1rem 0rem",
          }}
        >
          <Button
            disabled={name === "" || description === "" || loading}
            style={{
              background: "#1fc36157",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
              padding: "0.3rem 1rem",
              float: "right",
            }}
            onClick={handleStageFieldSubmit}
            size="small"
            endIcon={loading && <CircularProgress size={20} />}
          >
            Add Document Stage Data
          </Button>
        </ButtonWrapper>
      </ContainerDiv>
    </OuterContainer>
  );
};

export default DocumentStageForm;

const OuterContainer = styled.div`
  padding: 1.2rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
`;

const ContainerDiv = styled.div`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  grid-column: 1/3;
  justify-content: flex-end;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;
