import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment";

import Table from "../../components/shared/Table/Table";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import {
  TableOuterWrapper,
  TableWrapper,
} from "../../pages/CertificatesView/TokenCredentialsTableView";
import { SearchComponent } from "../../pages/JobListings/SearchComponent";
import { ColumnContainer } from "./IssueCertificateFormBulk";
import * as API from "../../api/API";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";

const ViewIssuedCredentials = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const [issuedData, setIssuedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [date, onDateChange] = useState();
  const [search, setSearch] = useState("");

  const fetchIssuedCredentials = async () => {
    setLoading(true);
    try {
      const response = await API.retrieveIssuedCredentials({
        path: { id: currentUser.publicKey },
        token: localStorage.niftoken,
      });

      if (response?.data?.code === 200) {
        setIssuedData(response.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIssuedCredentials();
  }, []);

  const dataHead = [
    "Item Name",
    "Email",
    "Blockchain",
    "Item Image",
    "Description",
    "Status",
  ];

  return (
    <Container gap>
      <ColumnContainer>
        <SubHeading primary>View Issued Collectibles</SubHeading>
        <Text small lighter color="#6b6b6b">
          View all Issued collectibles and Details
        </Text>
      </ColumnContainer>

      {loading ? (
        <InnerLoadingView style={{ marginTop: "-2rem" }} />
      ) : (
        <>
          <SearchComponent
            placeholder="Search for Collectibles"
            searchValue={search}
            onSearchChange={setSearch}
            dateValue={date}
            onDateChange={(e) => {
              onDateChange(e);
              setSearch(moment(e).format("L"));
            }}
          />
          <ColumnContainer>
            <TableOuterWrapper>
              <TableWrapper>
                <Table
                  theadData={dataHead}
                  tbodyData={issuedData?.filter((item) => {
                    if (search === "" && date === undefined) {
                      return item;
                    } else if (
                      item.itemName
                        ?.toLowerCase()
                        .includes(search?.toLowerCase())
                    ) {
                      return item;
                    } else if (
                      item?.description
                        .toLowerCase()
                        .includes(search?.toLowerCase())
                    ) {
                      return item;
                    } else if (
                      item?.email.toLowerCase().includes(search?.toLowerCase())
                    ) {
                      return item;
                    } else if (moment(item?.date).format("L").includes(search))
                      return item;
                  })}
                  credentialRow
                />
              </TableWrapper>
            </TableOuterWrapper>
          </ColumnContainer>
        </>
      )}
    </Container>
  );
};

export default ViewIssuedCredentials;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  min-height: 20rem;
`;
